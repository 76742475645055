import { isObject } from "tsparticles-engine";
export class CircleDrawer {
    draw(context, particle, radius) {
        if (!particle.circleRange) {
            particle.circleRange = { min: 0, max: Math.PI * 2 };
        }
        const circleRange = particle.circleRange;
        context.arc(0, 0, radius, circleRange.min, circleRange.max, false);
    }
    getSidesCount() {
        return 12;
    }
    particleInit(container, particle) {
        const shapeData = particle.shapeData, angle = shapeData?.angle ?? {
            max: 360,
            min: 0,
        };
        particle.circleRange = !isObject(angle)
            ? {
                min: 0,
                max: (angle * Math.PI) / 180,
            }
            : { min: (angle.min * Math.PI) / 180, max: (angle.max * Math.PI) / 180 };
    }
}
